<!-- 
	This is an empty page for layout demonstrations, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template><!-- 
	This is the sign up page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

	<div>

		<!-- Sign Up Image And Headings -->
		<div class="sign-up-header" style="background-image: url('https://coolbackgrounds.io/images/unsplash/samuel-zeller-medium-b832fe04.jpg')">
			<div class="content">
				<h1 class="mb-5">Sign Up</h1>
				<p class="text-lg">Use these awesome forms to login or create new account in your project for free.</p>
			</div>
		</div>
		<div class="mb-24">
			<div class="bg-white border-gray-3" style="height: calc(100vh - 89px); border: 1px solid">
			</div>
		</div>
	</div>
</template>

<script>

	export default ({
		data() {
			return {
			}
		},
	})

</script>

<style lang="scss">
</style>